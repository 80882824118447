$base: #263238;
$lite:  #2c2c2c;
$brand: #ff325d;
$size: 48px;


.loader-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
 .mainLoad {
    width : $size;
    height: $size;
    border-radius: 50%;
    display: block;
    border-top:4px solid $lite;
    border-right:4px solid transparent;
    animation: rotation 1s linear infinite;
	margin-right:auto;
	margin-left:auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width : $size;
      height: $size;
      border-radius: 50%;
      border-left:4px solid $brand;
      border-bottom:4px solid transparent;
      animation: rotation 0.5s linear infinite reverse;
    }
  }
*, *:after, *:before {
      box-sizing: border-box;
    }
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $base; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}

  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
  @keyframes rotationBack {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
  }
  @keyframes rotationBreak {
    0% { transform: rotate(0) }
    25% { transform: rotate(90deg)}
    50% { transform: rotate(180deg) }
    75% { transform: rotate(270deg)}
    100% { transform: rotate(360deg) }
  }
