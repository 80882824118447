.rating-container {
	width: 100%;
	display: flex;
	background-color: #fff;
	border-round: 5px;
	padding: 1rem;
	flex-direction: column;
}

.rating-block {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	&__title {
		display: block;
		text-align:center;
	}
	&__stars {
		text-align:center;
		display: block;
	}
}

@media screen and (min-width: 800px) {
	.rating-container {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		flex-direction: row;
	}
	.rating-block {
		margin-top: 0;
	}
}

