.com-container {
    font-size: 1rem;
    color: #4d4d4d;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    height: 100%;
	display: flex;
	flex-direction:column;
	width: 100%;
	&__title {
	}
	&__body {
		background-color: transparent; 
		font-size: 16px;
		font-weight: 400;
		width: 100%;
		font-style: normal;
		color: rgb(27,27,27);
		p, li, h1, h2, h3, h4, h6, h7 {
			padding: 0 20px;
		}
	}
	.comment {
		padding: 0;
		margin: 1rem 0;
		background-color: #fff;
		box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
		border-radius: 3px;
		display: flex;
		flex-direction:column;
		justify-content: space-between;
		&__title {
			display: flex;
			justify-content: center;
			align-items: center;
			width: auto;
			flex-grow: 1;
		}
		&__time, &__email {
			width: auto;
			font-weight: 100;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1rem;
			flex-grow: 1;
		}
		&__time {
			background-color: var(--bs-gray-300);
		}
		&__content {
			flex-grow: 2;
			font-weight: 100;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__submit {
			font-weight: 100;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: var(--bs-success);
			svg {
				width: 1.5rem;
			}
		}
		&__title, &__time, &__email, &__content {
			padding:0;
			span {
				padding:.5rem;
			}
		}
	}
}

@media (min-width: 800px) {
	.com-container > div:not(:first-child) {
		max-width: min(960px, 80%);
		margin-right: auto;
		margin-left: auto;
		.comment {
			flex-direction: row;
			&__time, &__email {
				border-left: 1px solid lightgrey;
				border-right: 1px solid lightgrey;
			}
			&__submit {
				padding: 1rem;
				svg {
					width: 2rem;
				}
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}
}
