
.navbar {
	height: 70px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	//background-color: /*rgb(19 43 60); rgb(45, 24, 92); */
	background-color:#26272b;
	flex-direction: row;
	justify-content: space-between;
	background-image: url('./bg.png');
	box-shadow: 0 4px 5px 0 rgb(0 0 0 / 24%), 0 2px 2px 0 rgb(0 0 0 / 25%), 0 3px 3px 0 rgb(0 0 0 / 22%);
}

.navbar__links {
	margin-left: auto;
	display: none;
	flex-direction: row;
	margin-right: 2rem;
}

.navbar__links a {
	color: #ff325d;
	text-decoration: none;
	text-transform: lowercase;
	position: relative;
	transition: .2s;
	z-index:100;
}
.navbar__links a:hover {
	color:#fff;}
.navbar__links a:after {
	content:''; 
	position: absolute;
	bottom: 0;
	left: 66%;
	display: none;
	height: 1px;
	width: 1px;
	z-index:50;
} 

.navbar__links a:hover:after {
	content:''; 
	position: absolute;
	bottom: 0;
	left: 66%;
	display: block;
	background: #fff;
	border-radius:50%;
	height: 7rem;
	width: 7rem;
	transform: translate(-2rem, 3.5rem);
	animation-duration: .5s;
	animation-name: zoom;
	z-index:50;
	opacity:.2;
}


@keyframes zoom {
  from {
	height:1px;
	width:1px;
	bottom:0;
	transform: translate(0, 0);
  }
  to {
	height: 7rem;
	width: 7rem;
	transform: translate(-2rem, 3.5rem);
  }
}

.navbar__links a:nth-child(n+2) {
	padding-left: 2rem;
}

.navbar__links a:nth-child(n+2):before {
	content: '';
	position: absolute;
	height: 1.8rem;
	top: 0;
	left: 1rem;
	border-left: 1px solid rgba(100,100,100,1);
}
.navbar__links a:last-child {
	margin-right: 1rem;
}
.active {
	color: #fff !important;
	font-weight: 800;
	transition: .5s;
}
.passive {

}

.navbar__logo {
	margin-left: 0.5rem;
	max-width: 180px;
}
.logo-link {
	display: flex;
	flex-direction: row;
}


// TOGGLER

//mixins
@mixin transition-mix(
  $property: all,
  $duration: 0.2s,
  $timing: linear,
  $delay: 0s
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

// COMMON STYLES !!!YOU DON'T NEED THEM

//basic variables
$theme-font-color: #2c2c2c;


.btn {
  @include transition-mix;

  padding: 10px 20px;
  display: inline-block;
  margin-right: 10px;

  font: {
    family: inherit;
    size: inherit;
  }

  background-color: #fff;
  border: 1px solid $theme-font-color;
  border-radius: 3px;

  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &.js-active {
    color: #fff;

    background-color: $theme-font-color;
  }
}

//header styles
.header {
  max-width: 600px;
  margin: 0 auto 50px;

  text-align: center;
}

.header__title {
  font: {
    size: 2.1rem;
    weight: 600;
  }
}

.header__subtitle {
  margin-bottom: 30px;

  font: {
    style: italic;
  }

  text-align: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  font: {
    size: 25px;
    weight: 700;
  }

  border-radius: 50%;
  background-color: #eee;
}

.toggler-close {
  top: 11px;
}

// NAV STYLES !!!YOU NEED THEM
$nav-dark-color: #2c2c2c;
$nav-light-color: #fff;
$nav-accent-color-1: #119da4;
$nav-accent-color-2: #ed474a;
$nav-accent-color-3: #568259;
$nav-accent-color-4: #422040;
$nav-accent-color-5: #02a9ea;
$nav-padding: 20px;

$toggler-width: 40px;
$toggler-height: 28px;

$toggler-bar-height: 3px;

.main-nav {
	border: 2px solid;
	border-image: linear-gradient(to left, turquoise, greenyellow) 0 0 1 0 ;
	position:relative;
	width: calc(100% + 4px);
	left: -2px;
  top: 0;

  padding: $nav-padding 0;

  background-color: $nav-dark-color;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

  z-index: 900;
}

.container {
  width: 95%;
  max-width: 1090px;
  height: 100%;
  margin: auto;
}

.main-nav__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-nav__toggler {
  position: relative;
  width: $toggler-width;
  height: $toggler-height;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1rem;
}

.toggler-open {
  &:hover,
  &:focus {
    .toggler-open__bar {
      &:nth-child(1) {
        transform: scaleX(1.1) translateY(-3px);
      }

      &:nth-child(2) {
        transform: scaleX(0.9);
      }

      &:nth-child(3) {
        transform: scaleX(1.1) translateY(3px);
      }
    }
  }
}

.toggler-open__bar {
  @include position-absolute($left: 0);
  @include transition-mix(
    $duration: 0.2s,
    $timing: cubic-bezier(0, 1.07, 1, 2.71)
  );

  width: 100%;
  height: $toggler-bar-height;

  background-color: $nav-light-color;
  border-radius: $toggler-bar-height;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: $toggler-bar-height * 4;
  }

  &:nth-child(3) {
    top: $toggler-bar-height * 8;
  }
}

.toggler-close {
  &:before,
  &:after {
    @include position-absolute($top: 45%, $left: 5%);
    @include transition-mix(
      $duration: 0.15s,
      $timing: cubic-bezier(0, 1.07, 1, 2.71)
    );

    display: block;
    width: 90%;
    height: $toggler-bar-height;

    content: "";

    background-color: $nav-dark-color;
    border-radius: $toggler-bar-height;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    &:before {
      transform: rotate(25deg);
    }

    &:after {
      transform: rotate(-25deg);
    }
  }
}

.main-nav__expandable {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.main-nav__expandable-inner {
  @include position-absolute($top: 0, $left: 0);

  width: 100%;
  height: 100%;
  padding: $nav-padding 0;

  background-color: $nav-light-color;

  z-index: 5;
}

.main-nav__expandable-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.main-nav__list {
  height: 100%;
  padding: 0;
  margin: 0;

  list-style-type: none;
}

.main-nav__item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.main-nav__link {
	&:hover {
		color: #ff325d;
	}
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: $nav-dark-color;
}

.main-nav__link-title {
  font: {
    size: 1.5rem;
    weight: 700;
  }
  letter-spacing: 2px;
  line-height:1;
}

.main-nav__link-descr {
  margin-top: 5px;

  font: {
    style: italic;
  }
}

// nav toggling - effect styles
.main-nav__expandable {
  &:before,
  &:after {
    @include position-absolute($top: 0, $left: 0);
    @include transition-mix($duration: 0.3s, $timing: ease-out);

    display: block;
    width: 100%;
    height: 100%;

    transform: scaleX(0);
    transform-origin: left center;

    content: "";

    z-index: 1;
  }

  &:before {
    background-color: $nav-dark-color;
  }

  &:after {
    transition-delay: 0.07s;
    background-color: rgba($nav-light-color, 0.7);
  }

  &.js-open {
    opacity: 1;
    visibility: visible;

    &:before,
    &:after,
    .main-nav__expandable-inner {
      transform: scaleX(1);
    }

    .main-nav__expandable-inner {
      @include transition-mix(
        $duration: 0.1s,
        $timing: ease-out,
        $delay: 0.1s
      );
    }

    .main-nav__list {
      @include transition-mix($duration: 0s, $delay: 0.4s);

      opacity: 1;
      visibility: visible;
    }

    .main-nav__item {
      @include transition-mix($duration: 0.1s);
      transform: translateY(0);

      opacity: 1;
      visibility: visible;

      &:nth-child(1) {
        transition-delay: 0.45s;
      }

      &:nth-child(2) {
        transition-delay: 0.55s;
      }

      &:nth-child(3) {
        transition-delay: .65s;
      }

      &:nth-child(4) {
        transition-delay: .75s;
      }

      &:nth-child(5) {
        transition-delay: .85s;
      }
    }

    .toggler-close {
      @include transition-mix(
        $duration: 0.2s,
        $delay: 0.65s,
        $timing: cubic-bezier(0.14, 1.01, 0.54, 1.38)
      );
      transform: translateX(0);

      opacity: 1;
      visibility: visible;
    }
  }
}

.main-nav__expandable-inner {
  transform: scaleX(0);
  transform-origin: left center;
}

.toggler-close {
  transform: translateX(15px);

  opacity: 0;
  visibility: hidden;
}

.main-nav__list,
.main-nav__item {
  opacity: 0;
  visibility: hidden;
}

@keyframes transformScaling {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left center;
  }
  50.5% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right center;
  }
}

.main-nav__item {
  position: relative;

  transform: translateY(20px);

  &:before {
    @include position-absolute($top: 0, $left: 0);

    display: block;
    width: 100%;
    height: 100%;

    content: "";

    transform: scaleX(0);
    transform-origin: left center;

    background-color: $nav-dark-color;

    z-index: 10;
  }

  &:after {
    @include position-absolute($top: -20px, $left: -20px);
    @include transition-mix($delay: 0s);

    display: block;

    font: {
      size: 5rem;
      weight: 700;
    }

    content: attr(data-add-text);

    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &:nth-child(1) {
    color: rgba($nav-accent-color-1, 0.2);
  }

  &:nth-child(2) {
    color: rgba($nav-accent-color-2, 0.2);
  }

  &:nth-child(3) {
    color: rgba($nav-accent-color-3, 0.2);
  }

  &:nth-child(4) {
    color: rgba($nav-accent-color-4, 0.2);
  }

  &:nth-child(5) {
    color: rgba($nav-accent-color-5, 0.2);
  }
}

.main-nav__link-title {
  @include transition-mix($delay: 0s);
}

.main-nav__item:hover {
  &:before {
    animation: transformScaling 0.4s linear 0s forwards;
  }

  &:after {
    @include transition-mix($delay: 0.45s, $timing: ease-out);

    opacity: 1;
    visibility: visible;
  }

  .main-nav__link-title {
    @include transition-mix($timing: ease-out, $delay: 0.45s);

    transform: translateX(10px);
  }
}

@media all and (min-width: 480px) {
	.main-nav__link-title {
	  font: {
		size: 2rem;
		weight: 700;
	  }
	  letter-spacing: 2px;
	  line-height:1;
	}
	.navbar__logo {
		margin-left: 1rem;
	}
	.main-nav__toggler {
		margin-right: 4rem;
	}
}
@media all and (min-width: 720px) {
	.main-nav__link-title {
	  font: {
		size: 2.4rem;
		weight: 700;
	  }
	  letter-spacing: 2px;
	  line-height:1;
	}
	.navbar__logo {
		margin-left: 1.5rem;
	}
}

@media all and (min-width: 920px) {
	.toggler-open {
		display: none;
	}
	.navbar__logo {
		margin-left: 2rem;
	}
	.navbar__links {
		display: flex;
	}
}

@media all and (min-width: 1240px) {
	.navbar__logo {
		margin-left: 1rem;
	}
}
