.site-footer
{
	border: 2px solid;
	border-image: linear-gradient(to left, turquoise, greenyellow) 1 0 0 0 ;
	position:relative;
	width: calc(100% + 4px);
	left: -2px;
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
  background-image: url('../../../img/bg.png');
  background-repeat: repeat;
  .social-icons {
	  a {
		  width:40px;
		  height:40px;
		  line-height:40px;
		  margin-left:6px;
		  margin-right:0;
		  border-radius:100%;
		  background-color:#33353d;
		  text-decoration:none;
	  }
	  a:hover {
		  text-decoration:none;
	  }
  }
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:lowercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  //color:#3366cc;
  color: var(--accent-color);
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none;
}
.footer-links li
{
  display:block
}
.footer-links a {
	color:#737373;
	text-transform:lowercase;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color: var(--accent-color);
  //color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.copyright-text
{
  margin:0
}
.quote {
	display:block;
}
.footer-block__left {
	align-items:right;
	text-align:left;
	justify-content:right;
}

@media (max-width:991px)
{
	.footer-block {
		  margin-bottom: 2rem;;
	}
	.footer-block__left {
		align-items:right;
		justify-content:right;
		text-align:right;
	}
}
@media (max-width:767px)
{
	.quote {
		display:none !important;
	}
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.vkontakte:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.tumblr:hover
{
  background-color:#395a76;
}
.social-icons a.youtube:hover
{
  background-color:#ea4c89
}
.social-icons a.instagram:hover {
	background-color: #fc04ae; 
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
