@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Black.woff2") format("woff2");
	font-weight: 900;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-BlackItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-BoldItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Italic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Light.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-LightItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-MediumItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: Roboto-thin;
	font-display: swap;
	src: url("../fonts/Roboto-Thin.woff2") format("woff2");
	font-weight: 100;
	font-style: normal;
}
@font-face{
	font-family: Roboto-thin;
	font-display: swap;
	src: url("../fonts/Roboto-ThinItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
