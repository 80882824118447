.page-container {
    font-family: Roboto, Sans-Serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    color: #4d4d4d;
    height: 100%;
	width: 100%;
	.page {
		display: flex;
		margin: 0;
		padding: 0;
		flex-direction:column;
		&__title {
			color: #4d4d4d;
		}

		span.h1, span.h2, span.h3, span.h4, span.h5, span.h6 {
			color: #5b5f72;
			font-weight: 800;
			font-family: Roboto, sans-serif;
			margin: 0.5em 0 0.3em;
			//color: red;
		}
		
		&__body {
			background-color: #f3f2ec;
			font-family: Roboto, 'Helvetiva Neue', sans-serif;
			font-size: 16px;
			font-weight: 400;
			width: 100%;
			font-style: normal;
			color: rgb(27,27,27);
			//border-radius: 0 0 5px 5px;
			//margin-bottom: 1rem;
			p, li, h1, h2, h3, h4, h6, h7 {
				padding: 0 20px;
			}
			p, li {
			}
			ul {
				margin: 2rem 0;
				list-style-type: none;
				list-style: none;
				padding: 0;
				display: flex;
				flex-direction: column;
			}
			li {
				margin: 1rem 0;
				line-height: 1.4;
				position: relative;
				margin: 1rem 0 1rem 60px;
				width: auto;
				display:block;
			}
			li:before {
				content:'✹';
				position: absolute;
				left: -30px;
				width: 30px;
				display:block;
			}
			p {
				margin: 2rem 0;
				line-height: 1.6;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}
	.title {
		--box-padding: calc( 15px + 45 * ((100vw - 320px) / 880));
		box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
		//background: linear-gradient(to bottom, #f5f5f5 0%, #ededed 100%);
		border-top: 1px solid #d9d9d9;
		border-bottom: 1px solid #d9d9d9;
		background-color: var(--background-dark);
		display: flex;
		justify-content: center;
		&__info {
			padding: 5vh var(--box-padding);
			span.h1 {
				margin-bottom: 5vh;
				font-size: 32.4px;
				line-height: 45px;
			}
			background-size: cover;
			background-attachment: fixed;
			height: auto;
			align-content: center ;
			position: relative;
			color: #f5f5f5;
		}
	}

@media (min-width: 1200px) {
	span.h1 {
		font-size: 40px;
	}
}

@media (min-width: 320px) {
}

.page-container {
	--box-padding: calc( 15px + 45 * ((100vw - 320px) / 880));
}
.page-container {
	--box-padding: 15px;
}
@media all and (min-width: 480px) {
	.title-info {
			font-size: 36px;
		&__category span{
			font-size: 1.1rem;
		}
		&__h1 span{
			font-size: 56px;
		}
		&__date {
			font-size: 1.1rem;
		}
		&__date span{
			font-size: 1.1rem;
		}
		&__excerpt {
			font-size: 1.1rem;
		}
		&__excerpt span{
			font-size: 1.1rem;
		}
	}
	.page-container {
		.page {
			&__body {
				font-size: 18px;
			}
		}
	}
}
@media all and (min-width: 720px) {
	.title-info {
		font-size: 52px;
		&__category span{
			font-size: 1.8rem;
		}
		&__h1 span{
			font-size: 72px;
		}
		&__date {
			font-size: 1.2rem;
		}
		&__date span{
			font-size: 1.2rem;
		}
		&__author {
			font-size: 1.2rem;
		}
		&__excerpt {
			font-size: 1.5rem;
		}
		&__excerpt span{
			font-size: 1.5rem;
		}
	}


}
@media (min-width: 800px) {
	.page-container {
		border-radius: 3px;
	}
	.page-container {
		.page> div:not(:first-child) {
			max-width: min(960px, 80%);
			margin-right: auto;
			margin-left: auto;
			box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
			background-color: #fff;
		}
		.title {
			&__info {
				max-width: min(960px, 80%);
			}
		}
	}
}

@media (min-width: 960px) {
	.page-container {
		.title {
			&__info {
				padding: 5vh 1rem 5vh 0;
				width: 960px;
			}
		}
	}

}


@media (min-width: 1200px) {
	.page-container {
		--box-padding: 60px;
	}
}

