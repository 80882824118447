@import "fonts";
:root {
	--bs-body-bg: #FDF5E6 !important;
	--accent-color: #ff325d;
	--background-dark: #2c2c2c;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: #f3f2ec !important;
    overflow-x: hidden;
    color: #4d4d4d;
    overflow-x: hidden;
	width: 100vw;
	background-repeat: repeat;
	font-family: "Roboto", Roboto, sans-serif !important;
	font-weight: 400;
	min-height:100vh;
}

body ::-webkit-scrollbar, body ::-webkit-scrollbar-corner {
    width: 0.5rem;
    background: #fff;
}
body ::-webkit-scrollbar-thumb {
    background: #dbdbdb;
}





#root {
	min-height:100vh;
}
.App {
	margin-bottom: 2rem;
	display: block;
}
.main {
	min-height:100vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

a {
    cursor: pointer;
    outline: 0;
    color: inherit;
    text-decoration: none;
    transition: border 125ms,-webkit-filter .25s;
    transition: filter .25s,border 125ms;
    transition: filter .25s,border 125ms,-webkit-filter .25s;
}


.App {
}

.post-flat {
	padding: 1rem;
}

.content-list {
	display: grid;
	gap: 15px;
	grid-auto-flow: dense;
	grid-auto-rows: auto;
	gap: 15px;
	& .tag {
		padding: 2px 5px;
		margin-top: 3px;
		margin-bottom: 3px;
		//background-color: rgb(80, 143, 22);
		//color: #ddd;
		border-radius:3px;
		display: block;
		opacity: 1;
		box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 1px -1px rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 12%);
		//background: linear-gradient(to bottom, #d1e4f6 0%, #c5d8e9 100%);
		background: linear-gradient(to bottom, #d1e4f6 0%, #c5d8e9 100%);
		text-shadow: 0 1px 0 #fff;
	}
	&__item {
		cursor: pointer;
		grid-column: span 1;
		grid-row: span 1;
		position: relative;
		border-radius: 5px;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.2),0 1px 5px 0 rgba(0,0,0,0.12);
		box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
		background:#fff;
		.background-layer {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius:5px;
		}
		.opacity-wrapper {
			position:absolute;
			border-radius:5px;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: hsla(0,0,0,0.0);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction:column;
		}
		.post-content {
			height:100%;
			padding-top: 4rem;
			display: flex;
			flex-direction:column;
			//padding-bottom: 30px;
			width:100%;
			height: 100%;
			&__h1 {
				color: #4d4d4d;
				font-size: 1.4rem;
				font-weight: 800;
				line-height: .9em;	
				padding-left: 15px;
				padding-right: 10px;
			}
			&__info {
				color: #787878;
				font-size: .6rem;
				margin: 10px 0;
				padding-left: 15px;
				padding-right: 10px;
				position: relative;
			}
			&__info:after {
			}
			&__excerpt {
				color: #4d4d4d;
				font-size: .9rem;
				line-height: 1.2rem;
				padding-left: 15px;
				padding-right: 10px;
				position: relative;
			}
			&__excerpt:before {
				content:'';
				width: 100%;
				height: 1px;
				background:#dbdbdb;
				position: absolute;
				top: -5px;
				left:0;
				z-index:3;
			}
			&__excerpt:after {
				content:'';
				width: 0;
				height: 1px;
				background: #d64f00;
				position: absolute;
				top: -5px;
				left:50%;
				z-index:4;
				transform:translateX(-50%);
			}
			&__tags {
				font-size: .6rem;
				display: flex;
				overflow-wrap: normal;
				font-size: .6em;
				margin-top: auto;
				margin-bottom: 10px;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				gap: 2px;
				padding-left: 15px;
				padding-right: 10px;
			}
			&__head {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				padding: 1rem 2rem;
				//background-color: #1e488f;
				//background-color: rgb(80, 143, 22);
				border-radius: 5px 0 0 0;
				//color: #eee;
				height: calc(2rem + 4px);
				background: linear-gradient(to bottom, #c5d8e9 0%, #c5d8e9 100%);
				flex: 0 0 auto;
				span {
					text-decoration:none;
					text-shadow: 0 1px 0 #fff;
					font-size: 1rem;
					line-height: 1;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: 800;
					letter-spacing: 1px;
					text-align:center;
				}
			}
			&__head:after {
				position: absolute;
				content: '';
				width: 0;
				height: 0;
				top:0;
				right: calc(-2rem - 4px);
				border-top: calc(2rem + 4px) solid #c5d8e9;
				border-right: calc(2rem + 4px) solid transparent;
			}
		}
		.post-content:after {
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			bottom: 0;
			right:0;
			border-right: 2rem solid #dbdbdb;
			border-top: 2rem solid transparent;
		}
		.post:hover {
			.opacity-wrapper {
				background-color: rgba(155,199,0,0.21);
				//background-color: hsla(0,0,0,0.1);
			}
		}
		.post:hover {
			.post-content__excerpt:after {
				width: 100%;
				transition: .5s;
			}
		}
	}
	&__item_big {
		grid-column: span 1;
		grid-row: span 1;
		padding: 0 0 50% 0;
		.background-layer {
			background-repeat: no-repeat repeat;
			background-size: cover;
			background-position: center;
		}
		.opacity-wrapper {
			background-color: hsla(0,0,0,0.3);
		}
		.post-content {
			height: auto;
			background-color: hsla(0,0,0,0.3);
			&__h1 {
				color: #eee;
				font-size: 2em;
				font-weight: 800;
				line-height: 1.2em;	
			}
			&__info {
				color: #aaa;
				font-size: .7em;
				margin: 5px 0;
			}
			&__info:after {
				display: none;}
			&__excerpt {
				color: #ddd;
				font-size: 1em;
				line-height: 1.2em;
			}
			&__tags {
				display: flex;
				overflow-wrap: normal;
				font-size: .8rem;
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}
		.post-content:after {
			display: none;
		}
		.post:hover {
			.opacity-wrapper {
				//background-color: rgba(155,199,0,0.41);
				background-color: hsla(0,0,0,0.1);
			}
		}
		.tag {
			display: block;
			opacity: 1;
			background: linear-gradient(to bottom, #d1e4f6 0%, #c5d8e9 100%);
			text-shadow: 0 1px 0 #fff;
		}
	}

	.post {
		display: flex;
		border-radius: 5px;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		color: #222;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat repeat;
		background-size: cover;
		background-position: center;
		overflow:hidden;
		&:hover .background-layer, &:focus .background-layer {
			transform: scale(1.2);
			transition: all .5s;
		}
		
	}
}



// CSSTransition
.item-enter {
opacity: 0;
}
.item-enter-active {
opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}




.page__wrapper {
	margin: 30px 0;
	display: flex;
	align-items:center;
	justify-content: center;
}
.paginaton__button {
	padding: 10px;
	border: 1px solid teal;
	cursor: pointer;
	margin-right: 3px;
}
.paginaton__button.current {
	border: 2px solid orange;
	font-weight: bold;
}


.content-list {
	&__item {
		position: relative;
		padding: 0;
		.post {
			position: relative;
		}
		.opacity-wrapper {
			position: relative;
		}
		.post-content {
			padding-top: 2rem;
			&__head {
				height: calc(1rem + 4px);
				padding: 0.2rem 2rem;
				span {
					font-size: .8rem;
				}
			}
			&__head:after{
				border-top: calc(1rem + 4px) solid #c5d8e9;
				border-right: calc(1rem + 4px) solid transparent;
				right: calc(-1rem - 4px);
			}
		}
		&_big {
			.post-content {
				padding-top: 4rem;
				&__h1 {
					font-size: 1.5rem;
					line-height: 1;
				}
				&__tags {
					font-size: .6rem;
				}
			}
		}
	}
}

.img-link {
	
}
.img-link:hover {
	opacity: .8;	
	transition: .4s;
}

@media (min-width: 480px) {
	.content-list {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		gap: 15px;
		&__item {
			padding: 0 0 100% 0;
			.post {
				position: absolute;
				.opacity-wrapper {
					position: absolute;
				}
			}
			.post-content {
				padding-top: 4rem;
				&__head {
					height: calc(2rem + 4px);
					padding: 1rem 2rem;
					span {
						font-size: 1rem;
					}
				}
				&__head:after{
					border-top: calc(2rem + 4px) solid #c5d8e9;
					border-right: calc(2rem + 4px) solid transparent;
					right: calc(-2rem - 4px);
				}
			}
			&_big {
				.post-content {
					&__h1 {
						font-size: 1.5rem;
						line-height: 1;
					}
				}
			}
		}
	}
}
@media (min-width: 720px) {
	body {
		background-image:url('./back.png');
	}
	.content-list {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-columns: repeat(3, 1fr);
		grid-auto-rows: 1fr;
		&__item {
			.post {
				.opacity-wrapper {
				}
			}
			.post-content {
				padding-top: 4rem;
				&__head {
					height: calc(2rem + 4px);
					padding: 1rem 2rem;
					span {
						font-size: 1rem;
					}
				}
				&__head:after{
					border-top: calc(2rem + 4px) solid #c5d8e9;
					border-right: calc(2rem + 4px) solid transparent;
					right: calc(-2rem - 4px);
				}
			}
			&_big {
				.post-content {
					&__h1 {
					}
					&__tags {
					}
				}
			}
		}
	}
}
@media (min-width: 960px) {
	.content-list {
		grid-template-columns: repeat(4, 1fr);
		grid-auto-columns: repeat(4, 1fr);
		&__item {
			&_big {
				.post-content {
					&__h1 {
						font-size: 2rem;
						line-height: 1.2;
					}
					&__tags {
						font-size: .8rem;
					}
				}
			}
		}
	}
	.content-list {
		&__item_big {
			grid-column: span 2;
			grid-row: span 2;
			position: relative;
			padding: 0 0 100% 0;
		}
	}
	.post-flat {
		padding: 1rem 0;
	}
}








