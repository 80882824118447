
.load-more {
	button {
	width: 90%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
	padding: 1rem;
	font-size: 2rem;
	font-weight: 700;
	display: inline-block;
	text-align: center;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	vertical-align: middle;
	border: 1px solid #505858;
	padding: 8px 12px 8px 12px;
	font-size: 30px;
	color: #505858;
	text-decoration: none;
	min-width: 200px;
	cursor: pointer;
	display: block;
	}
	button:hover, button:focus {
	background-color: tomato;
	border-color: tomato;
	color: #fff;
	}
}
